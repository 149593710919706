<template>
  <div class="home">
    <!-- <div style="background-color:#FAFAFA"> -->
    <div class="bc2 pt-60 tc">
      <a-radio-group
        size="large"
        v-model="radioType"
        @change="handleChange"
        button-style="solid"
      >
        <a-radio-button value="database">
          {{ $t('licence.databaseProgress') }}
        </a-radio-button>
        <a-radio-button value="openProject">
          {{ $t('licence.openProjectProgress') }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="bc2 pb-150" v-show="radioType == 'database'">
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{ $t('licence.cooperation') }}</div>
      </div>
      <div class="f24 p-0-250">
        <div class="disflex justify-sb">
          <div class="part disflex p6-12">
            <img src="@/assets/images/nf.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.nanFangHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/bjtt.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.bjttHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/sdsl.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.sdslHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/zdfsde.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.zjdaxfsdeHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/syf.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.syfHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/syx.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.syxHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/sddy.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.szdermHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/dndfszd.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.zdHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/fdek.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.fdfusekHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/scrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.scsrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/hzzxrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.hzzxrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/hzkj.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.hzkjtjHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/mmrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.mmrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/zgkjdaxfsdy.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.zgkjdaxfsdyHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/gdszy.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.gdszyHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/gzsrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.gzsrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/fssdyrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.fssdyrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/gdykdxfs.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.gdykdxfsHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/nfykdaxfsds.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.nfykdaxfsdsHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/nfykdxfssd.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.nfykdxfssdHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/hz.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.hzHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/kmdxfsde.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.kmdxfsdeHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/zsdxfsdw.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.zsdxfsdwHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/qhdxfs.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.qhdxfscqxnHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/cqxn.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.cqxnHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/wuhan.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.wuhanHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/zhedafushufrst.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.zjdaxfsdyHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/xjykdyfs.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.xjykdyfsHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/glyxyfs.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.glyxyfsHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/dlykdxfsde.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.dlykdxfsdeHospital') }}</div>
          </div>
        
        </div>
        <div class="disflex justify-sb mt-20">
        <div class="part disflex p6-12">
            <img
              src="@/assets/images/haikouLogo.png"
              height="60"
              class="pr-10"
            />
             <div class="f19">{{ $t('licence.haikouHospital') }}</div>
        </div>
        <div class="part disflex p6-12">
                <img
                src="@/assets/images/shfsdj.png"
                height="60"
                class="pr-10"
                />
                <div class="f19">{{ $t('licence.shjtfsdjHospital') }}</div>
        </div>
          <div class="part disflex p6-12">
                <img
                src="@/assets/images/kl.png"
                height="60"
                class="pr-10"
                />
                <div class="f19">{{ $t('licence.klHospital') }}</div>
          </div>
        </div>
      </div>
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{ $t('licence.dataInputting') }}</div>
      </div>
      <div class="f24 p-0-250">
        <div class="disflex justify-sb">
         
          <div class="part disflex p6-12">
            <img src="@/assets/images/zjdxfset.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.zjdxfsetHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/sgrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.sgrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/shdyrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.shdyrmHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/szrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.szrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/hzsfnetylzx.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.hzsfnetylzxHospital') }}</div>
          </div>
          <div class="part disflex">
            <!-- <img src="@/assets/images/cqxn.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.cqxnHospital') }}</div> -->
          </div>
        </div>
      </div>
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{ $t('licence.datareviewing') }}</div>
      </div>
      <div class="f24 p-0-250">
        <div class="disflex justify-sb">
          <div class="part disflex p6-12">
            <img src="@/assets/images/xjhrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.xjhrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/rk.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.rkHospital') }}</div>
          </div>
          <div class="part disflex">
            <img src="@/assets/images/yuebei.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.yuebeiHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/youan.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.youanminzu') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/hainanHospital.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.heinanHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/zsdxdsfu.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.zsdxdsfs') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/hbykdxdyyy.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.hbykdxdyyy') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/nhykdxfsdyyy.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.nhykdxfsdyyy') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/zndxxyyy.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.zndxxyyy') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/zsfsdb.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.zsfsdbHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/whds.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.whdsHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/xmfs.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.xmfsHospital') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bc2 pb-150" v-show="radioType == 'openProject'">
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{ $t('licence.completed') }}</div>
      </div>
      <div class="f24 p-0-250">
        <div class="disflex justify-sb">
          <div class="part disflex p6-12">
            <img src="@/assets/images/dndfszd.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.zdHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/scrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.scsrmHospital') }}</div>
          </div>
          <div class="part disflex"></div>
        </div>
      </div>
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{ $t('licence.projectOngoing') }}</div>
      </div>
      <div class="f24 p-0-250">
        <div class="disflex justify-sb">
          <div class="part disflex p6-12">
            <img src="@/assets/images/zdfsde.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.zjdaxfsdeHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/zgkjdaxfsdy.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.zgkjdaxfsdyHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/nfykdaxfsds.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.nfykdaxfsdsHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/fssdyrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.fssdyrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/sddy.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.szdermHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/hzkj.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.hzkjtjHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img
              src="@/assets/images/nfykdxfssd.png"
              height="60"
              class="pr-10"
            />
            <div class="f19">{{ $t('licence.nfykdxfssdHospital') }}</div>
          </div>
          <div class="part disflex"></div>
          <div class="part disflex"></div>
        </div>
      </div>
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{ $t('licence.underReview') }}</div>
      </div>
      <div class="f24 p-0-250">
        <div class="disflex justify-sb">
          <div class="part disflex p6-12">
            <img src="@/assets/images/shdyrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.shdyrmHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/fdek.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.fdfusekHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/gzsrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.gzsrmHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/gdykdxfs.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.gdykdxfsHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/syf.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.syfHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/gdszy.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.gdszyHospital') }}</div>
          </div>
        </div>
        <div class="disflex justify-sb mt-20">
          <div class="part disflex p6-12">
            <img src="@/assets/images/syx.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.syxHospital') }}</div>
          </div>
          <div class="part disflex p6-12">
            <img src="@/assets/images/hzzxrm.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.hzzxrmHospital') }}</div>
          </div>
          <div class="part disflex"></div>
        </div>
      </div>
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{ $t('licence.applying') }}</div>
      </div>
      <div class="f24 p-0-250">
        <div class="disflex justify-sb">
          <div class="part disflex p6-12">
            <img src="@/assets/images/bjtt.png" height="60" class="pr-10" />
            <div class="f19">{{ $t('licence.bjttHospital') }}</div>
          </div>
          <div class="part disflex"></div>
          <div class="part disflex"></div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <my-footer></my-footer>
  </div>
</template>

<script>
import myFooter from '@/components/footer.vue';

export default {
  name: 'Home',
  components: {
    myFooter,
  },
  data() {
    return {
      radioType: this.$route.params.type,
    };
  },
  created() {
    this.radioType = this.$route.params.type;
  },
  watch: {
    $route: {
      handler: function (route) {
        this.radioType = route.params.type;
      },
      immediate: true,
    },
  },
  methods: {
    handleChange(e) {
      this.$router.push({ name: 'Project', params: { type: e.target.value } });
    },
  },
};
</script>
<style lang="scss" scoped>
.pro-schedule {
  padding: 60px 250px 30px 250px;
}
.p-0-250 {
  padding: 0 250px;
}
.part {
  width: 31%;
  background-color: #fff;
}
.vertical {
  width: 8px;
  height: 32px;
  margin-right: 10px;
  background: #33b5ee;
}
.p6-12 {
  padding: 6px 12px;
}
</style>
