<template>
  <div>
    <div class="news f30 tc">
      {{$t('licence.News4Title')}}
    </div>
    <div class="line"></div>
    <div class="p-0-250 f22">
      <div class="mytext mb-20">
        {{$t('licence.News4Part1')}}
      </div>
      <div class="mytext mb-20">
        {{$t('licence.News4Part2')}}
      </div>
      <div class="mytext mb-20">
        {{$t('licence.News4Part3')}}
      </div>
      <div class="tc">
        <img
          src="@/assets/images/news4-1.png"
          height="560"
          alt=""
        >
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import myFooter from "@/components/footer.vue";

export default {
  components: {
    myFooter,
  },
  methods: {
    goLink(name) {
      this.$router.push({ name: name });
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 3px solid #1f368b;
  margin: 0 250px 50px;
}
.img-title {
  color: rgba(0, 0, 0, 0.6);
}
.news {
  padding: 70px 250px 50px;
}
.p-0-250 {
  padding: 0 250px;
}
.mytext {
  text-indent: 30px;
}
</style>
