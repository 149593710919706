<template>
  <div class="home">
    <div class="bc1 flex-dir-col align-center pt-50 pb-50">
      <div class="f40">{{ $t('licence.curData') }}</div>
      <div class="line"></div>
    </div>
    <div class="bc1 p-0-250-60-250">
      <div class="disflex justify-sb">
        <div class="disflex" style="width: 33%">
          <div class="bg-color-white tc p5">
            <img height="48" src="@/assets/images/plus.png" />
          </div>
          <div class="ml-30">
            <div class="f48 fb">28</div>
            <div class="f20">{{ $t('licence.completedHospitals') }}</div>
          </div>
        </div>
        <div class="disflex" style="width: 33%">
          <div class="bg-color-white tc pt-5 pb-5" style="width: 58px">
            <img height="48" src="@/assets/images/person.png" />
          </div>
          <div class="ml-30">
            <div class="f48 fb">1,029.0</div>
            <div class="f20">{{ $t('licence.numberPatients') }}</div>
          </div>
        </div>
        <div class="disflex" style="width: 33%">
          <div class="bg-color-white tc pt-5 pb-5" style="width: 58px">
            <img height="48" src="@/assets/images/dui.png" />
          </div>
          <div class="ml-30">
            <div class="f48 fb">1,676.0</div>
            <div class="f20">{{ $t('licence.hospitalizationsTimes') }}</div>
          </div>
        </div>
      </div>
      <div class="disflex justify-sb mt-40">
        <div class="disflex" style="width: 33%">
          <div class="bg-color-white tc pt-5 pb-5" style="width: 58px">
            <img height="48" src="@/assets/images/zhe.png" />
          </div>
          <div class="ml-30">
            <div class="f48 fb">70.0</div>
            <div class="f20">{{ $t('licence.dataMillion') }}</div>
          </div>
        </div>
        <div class="disflex" style="width: 33%">
          <div class="bg-color-white tc pt-5 pb-5" style="width: 58px">
            <img height="48" src="@/assets/images/1.png" />
          </div>
          <div class="ml-30">
            <div class="f48 fb">263.0</div>
            <div class="f20">{{ $t('licence.visitsTimes') }}</div>
          </div>
        </div>
        <div class="disflex" style="width: 33%">
          <div class="bg-color-white tc pt-5 pb-5" style="width: 58px">
            <img height="48" src="@/assets/images/3.png" />
          </div>
          <div class="ml-30">
            <div class="f48 fb">133.0</div>
            <div class="f20">{{ $t('licence.visits3Year') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-dir-col align-center pt-50">
      <div class="f40">{{ $t('licence.certification') }}</div>
      <div class="line"></div>
    </div>
    <div class="p-0-250-60-250 disflex justify-c">
      <img src="@/assets/images/zz1.png" height="400" class="mr-20" />
      <img src="@/assets/images/zz2.png" height="400" class="mr-20" />
      <img src="@/assets/images/zz3.png" height="400" />
    </div>
    <div class="bc1 flex-dir-col align-center pt-50 pb-50">
      <div class="f40">{{ $t('licence.dataModel') }}</div>
      <div class="line"></div>
    </div>
    <div class="bc1 p-0-250-60-250">
      <div
        class="disflex justify-sa"
        :class="{ 'height-en': $i18n.locale == 'en' }"
      >
        <div style="height: 100%" class="disflex f25 data-model pt-5 pb-5">
          <div class="index">01</div>
          <div>{{ $t('licence.perInfo') }}</div>
        </div>
        <div style="height: 100%" class="disflex f25 data-model pt-5 pb-5">
          <div class="index">02</div>
          <div>{{ $t('licence.inspecInfo') }}</div>
        </div>
        <div style="height: 100%" class="disflex f25 data-model pt-5 pb-5">
          <div class="index">03</div>
          <div>{{ $t('licence.surgeryProcedureInfo') }}</div>
        </div>
      </div>
      <div
        class="disflex justify-sa mt-30"
        :class="{ 'height-en': $i18n.locale == 'en' }"
      >
        <div style="height: 100%" class="disflex f25 data-model pt-5 pb-5">
          <div class="index">04</div>
          <div>{{ $t('licence.visitInformation') }}</div>
        </div>
        <div style="height: 100%" class="disflex f25 data-model pt-5 pb-5">
          <div class="index">05</div>
          <div>{{ $t('licence.medOrders') }}</div>
        </div>
        <div style="height: 100%" class="disflex f25 data-model pt-5 pb-5">
          <div class="index">06</div>
          <div>{{ $t('licence.vitalSigns') }}</div>
        </div>
      </div>
      <div
        class="disflex justify-sa mt-30"
        :class="{ 'height-en': $i18n.locale == 'en' }"
      >
        <div style="height: 100%" class="disflex f25 data-model pt-5 pb-5">
          <div class="index">07</div>
          <div>{{ $t('licence.clinicalDiagnosticInfo') }}</div>
        </div>
        <div style="height: 100%" class="disflex f25 data-model pt-5 pb-5">
          <div class="index">08</div>
          <div>{{ $t('licence.otherMedicalOrder') }}</div>
        </div>
        <div style="height: 100%" class="disflex f25 data-model pt-5 pb-5">
          <div class="index">09</div>
          <div>{{ $t('licence.endEvents') }}</div>
        </div>
      </div>
    </div>
    <div class="bc2 flex-dir-col align-center pt-50 pb-50">
      <div class="f40">{{ $t('licence.ourDevices') }}</div>
      <div class="line"></div>
    </div>
    <div class="bc2 mytext f24 p-0-250-60-250">
      {{ $t('licence.devicesInfo') }}
    </div>
    <div class="bc2 flex-dir-col align-center pt-50 pb-50">
      <div class="f40">{{ $t('licence.technicalSupport') }}</div>
      <div class="line"></div>
    </div>
    <div class="bc2 mytext f24 p-0-250-150">
      {{ $t('licence.technicalSupportInfo') }}
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import myFooter from '@/components/footer.vue';

export default {
  name: 'Home',
  components: {
    myFooter,
  },
};
</script>
<style lang="scss" scoped>
.line {
  width: 100px;
  height: 0px;
  border-bottom: 5px solid #5db2e9;
}
.mytext {
  // text-indent: 30px;
}
.p-0-250-60-250 {
  padding: 0 250px 60px 250px;
}
.p-0-250-150 {
  padding: 0 250px 150px;
}
.data-model {
  width: 270px;
  background-color: #fff;
  padding-left: 30px;
  .index {
    color: #4dafe1;
    margin-right: 10px;
  }
}
.height-en {
  height: 113px;
}
</style>
