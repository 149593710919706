<template>
  <div class="home">
    <div class="bc2 disflex team">
      <div class="vertical"></div>
      <div class="f40">{{$t('licence.teamScale')}}</div>
    </div>
    <div class="bc2 f24 p-0-250">{{$t('licence.teamInfo')}}</div>
    <div class="bc2 disflex team">
      <div class="vertical"></div>
      <div class="f40">{{$t('licence.experts')}}</div>
    </div>
    <div class="bc2" style="padding-bottom:150px">
      <div class="experts">
        <img
          src="@/assets/images/hff.png"
          height="150"
          class="pr-10"
        >
        <div class="f24">{{$t('licence.hff')}}</div>
        <div class="f16 pb-10">{{$t('licence.nanFangHospital')}}</div>
        <div
          class="f16"
          style="color: #707070;"
        >{{$t('licence.hffIntroduce')}}</div>
        <div style="clear:both;"></div>
      </div>
      <div class="experts">
        <img
          src="@/assets/images/lm.png"
          height="150"
          class="pr-10"
        >
        <div class="f24">{{$t('licence.lm')}}</div>
        <div class="f16 pb-10">{{$t('licence.nanFangHospital')}}</div>
        <div
          class="f16"
          style="color: #707070;"
        >{{$t('licence.lmIntroduce')}}</div>
        <div style="clear:both;"></div>
      </div>
      <div class="experts">
        <img
          src="@/assets/images/xx.png"
          height="150"
          class="pr-10"
        >
        <div class="f24">{{$t('licence.xx')}}</div>
        <div class="f16 pb-10">{{$t('licence.nanFangHospital')}}</div>
        <div
          class="f16"
          style="color: #707070;"
        >{{$t('licence.xxIntroduce')}}</div>
        <div style="clear:both;"></div>
      </div>
      <div class="experts">
        <img
          src="@/assets/images/xh.png"
          height="150"
          class="pr-10"
        >
        <div class="f24">{{$t('licence.xh')}}</div>
        <div class="f16 pb-10">{{$t('licence.fdekHospital')}}</div>
        <div
          class="f16"
          style="color: #707070;"
        >{{$t('licence.xhIntroduce')}}</div>
        <div style="clear:both;"></div>
      </div>
      <div class="experts">
        <img
          src="@/assets/images/xg.png"
          height="150"
          class="pr-10"
        >
        <div class="f24">{{$t('licence.xg')}}</div>
        <div class="f16 pb-10">{{$t('licence.hzkjtjHospital')}}</div>
        <div
          class="f16"
          style="color: #707070;"
        >{{$t('licence.xgIntroduce')}}</div>
        <div style="clear:both;"></div>
      </div>
      <div class="experts">
        <img
          src="@/assets/images/wlm.png"
          height="150"
          class="pr-10"
        >
        <div class="f24">{{$t('licence.wlm')}}</div>
        <div class="f16 pb-10">{{$t('licence.gjjk')}}</div>
        <div
          class="f16"
          style="color: #707070;"
        >{{$t('licence.wlmIntroduce')}}</div>
        <div style="clear:both;"></div>
      </div>
      <div class="experts">
        <img
          src="@/assets/images/zyg.png"
          height="150"
          class="pr-10"
        >
        <div class="f24">{{$t('licence.zmg')}}</div>
        <div class="f16 pb-10">{{$t('licence.zgjb')}}</div>
        <div
          class="f16"
          style="color: #707070;"
        >{{$t('licence.zmgIntroduce')}}</div>
        <div style="clear:both;"></div>
      </div>
      <div class="experts">
        <img
          src="@/assets/images/zy.png"
          height="150"
          class="pr-10"
        >
        <div class="f24">{{$t('licence.zy')}}</div>
        <div class="f16 pb-10">{{$t('licence.zkyjss')}}</div>
        <div
          class="f16"
          style="color: #707070;"
        >{{$t('licence.zyIntroduce')}}</div>
        <div style="clear:both;"></div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import myFooter from "@/components/footer.vue";

export default {
  name: "Home",
  components: {
    myFooter,
  },
};
</script>
<style lang="scss" scoped>
.line {
  width: 100px;
  height: 0px;
  border-bottom: 5px solid #5db2e9;
}
.team {
  padding: 40px 250px 20px 250px;
}
.p-0-250 {
  padding: 0 250px;
}
.experts {
  margin: 0px 250px 40px 250px;
  padding: 20px 40px;
  word-break: break-all;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  img {
    float: left;
  }
}
.vertical {
  width: 8px;
  height: 32px;
  margin-right: 10px;
  background: #33B5EE;
}

.data-model {
  width: 30%;
  .index {
    color: #4dafe1;
    margin-right: 10px;
  }
}
</style>
