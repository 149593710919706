import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import './assets/css/antd.scss'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import VueI18n from 'vue-i18n'
import echarts from 'echarts';
Vue.use(Antd)
Vue.use(VueI18n)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
const i18n = new VueI18n({
  locale: (function () {
    // if (localStorage.getItem('lang')) {
    //   return localStorage.getItem('lang')
    // }
    return window.navigator.language == 'zh-CN' ? 'zh' : 'en'
  }()),
  messages: {
    'en': require('@/assets/language/en'),
    'zh': require('@/assets/language/zh')
  }
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  window.pageYOffset = 0
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
