<template>
  <div>
    <div class="news f30 tc">
      {{$t('licence.News5Title')}}
    </div>
    <div class="line"></div>
    <div class="p-0-250 f22">
      <div class="mytext mb-20">
        {{$t('licence.News5Part1')}}
      </div>
      <div class="mytext mb-20">
        {{$t('licence.News5Part2')}}
      </div>
      <div class="tc">
        <img
          src="@/assets/images/banner-3.png"
          height="560"
          alt=""
        >
        <div class="f18 mt-10 mb-30 img-title">{{$t('licence.News5P1')}}</div>
      </div>
      <div
        style="font-weight: 500;"
        class="mt-40 mb-30"
      >{{$t('licence.News5T1')}}</div>
      <div class="mytext mb-20">
         {{$t('licence.News5Part3')}}
      </div>
      <div class="mytext mb-20">
        {{$t('licence.News5Part4')}}
      </div>
      <div class="mytext mb-20">
        {{$t('licence.News5Part5')}}
      </div>
      <div class="tc">
        <img
          src="@/assets/images/news5-1.png"
          height="560"
          alt=""
        >
        <div class="f18 mt-10 mb-30 img-title">{{$t('licence.News5P2')}}</div>
      </div>
      <div
        style="font-weight: 500;"
        class="mt-40 mb-30"
      >{{$t('licence.News5T2')}}</div>
      <div class="mytext mb-20">
        {{$t('licence.News5Part6')}}
      </div>
      <div class="mytext mb-20">
        {{$t('licence.News5Part7')}}
      </div>
      <div
        style="font-weight: 500;"
        class="mt-40 mb-30"
      >{{$t('licence.News5T3')}}</div>
      <div class="mytext mb-20">
        {{$t('licence.News5Part8')}}
      </div>
      <div class="mytext mb-20">
        {{$t('licence.News5Part9')}}
      </div>
      <div class="tc">
        <img
          src="@/assets/images/news5-2.png"
          height="560"
          alt=""
        >
        <div class="f18 mt-10 mb-30 img-title">{{$t('licence.News5P3')}}</div>
      </div>
      <div
        style="font-weight: 500;"
        class="mt-40 mb-30"
      >{{$t('licence.News5T4')}}</div>
      <div class="mytext">
        {{$t('licence.News5Part10')}}
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import myFooter from "@/components/footer.vue";

export default {
  components: {
    myFooter,
  },
  methods: {
    goLink(name) {
      this.$router.push({ name: name });
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 3px solid #1f368b;
  margin: 0 250px 50px;
}
.img-title {
  color: rgba(0, 0, 0, 0.6);
}
.news {
  padding: 70px 250px 50px;
}
.p-0-250 {
  padding: 0 250px;
}
.mytext {
  text-indent: 30px;
}
</style>
