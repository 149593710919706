module.exports = {
  licence: {
    allRightsReserved: '版权所有',
    footer: '国家肾脏病临床医学研究中心',
    title: '中国肾脏病大数据协作网平台',
    home: '首页',
    database: '数据库简介',
    expert: '专家介绍',
    project: '项目进度',
    news: '新闻动态',
    join: '加入我们',
    futures: '大数据引领肾脏病学未来',
    commonInterconnected: '互联共通',
    cooperationSharing: '合作共享',
    briefIntroduction: '平台介绍',
    briefIntroductionInfos:
      '肾脏病是罹及我国1.2亿人群的公共健康问题，多数肾脏病的发病机制不明，缺乏有效治疗 手段，亟需多中心临床研究揭示我国的发病率、风险因素、临床预后和治疗现状。为此， 由国家肾脏病临床医学中心与中国疾病预防控制中心慢病中心牵头，于2018年12月成立了 我国第一个“中国肾脏病大数据协作网”（以下简称“协作网”），以建立肾脏病医疗健 康大数据平台，加速肾脏病的研究进展，完善合作共享机制和培养专业人才队伍为目标， 共同提高我国肾脏病的防治水平。在中国科学院院士侯凡凡的领导下，目前已有超过35家 三级医院加入该网络，覆盖我国北方、中部、西部和南方18个省份。',
    database: '数据库介绍',
    databaseInfos:
      '数据内容来自个人信息表、住院信息表、临床诊断信息表、检验信息表、药物医嘱信息表、其他医嘱信息表、手术操作信息表、生命体征表、终点时间表',
    more: '了解更多',
    team: '我们的专家团队',
    partners: '合作伙伴',
    links: '友情链接',
    contact: '联系方式',
    nanFangHospital: '南方医科大学南方医院',
    fdekHospital: '复旦大学附属儿科医院',
    hzkjtjHospital: '华中科技大学同济医学院附属同济医院',
    gjjk: '国家疾控中心慢病中心',
    zgjb: '中国疾病预防控制中心',
    zkyjss: '中科院计算所',
    curData: '我们现在的数据',
    certification: '资质认证',
    dataModel: '数据模型',
    ourDevices: '我们的硬件',
    technicalSupport: '技术支持',
    completedHospitals: '已完成数据清理医院数量(家)',
    completed: '已完成',
    numberPatients: '住院患者数量(万人)',
    hospitalizationsTimes: '总住院次数(万次)',
    dataMillion: '总数据量(亿条)',
    visitsTimes: '1年以上随访人数(万次)',
    visits3Year: '3年以上随访人数(万次)',
    perInfo: '个人信息表',
    inspecInfo: '检验信息表',
    surgeryProcedureInfo: '手术操作信息表',
    visitInformation: '就诊信息表',
    medOrders: '药物医嘱信息表',
    vitalSigns: '生命体征表',
    clinicalDiagnosticInfo: '临床诊断信息表',
    otherMedicalOrder: '其他医嘱信息表',
    endEvents: '终点事件表',
    devicesInfo:
      '中心配备有12台高性能服务器，22*CPU 6240，13*GPU V100+2*GPU A100，10T总运行内存，375T存储空间，具有强大的数据储存、处理和分析能力。数据处理及分析服务器仅连接独立的局域网；服务器连接通过堡垒机，记录所有用户操作行为；连接服务器的计算机终端禁止外部设备接入端口（U盘、光盘等）。',
    technicalSupportInfo:
      '神州医疗聚焦医疗大数据、智能影像平台、精准医疗和药企数字化四大核心领域，提供医疗大数据平台、神飞云产品家族、基因组学分析与精准医学服务、药企全生命周期大数据应用、医学人工智能等全系列产品及解决方案，公司承担了多项国家大数据平台建设，在肿瘤、罕见病、心脑血管、肾脏病等专病领域具备扎实的技术积累与数据沉淀。公司与美国、英国、德国、新加坡、荷兰等多个国家的权威研究机构深度合作，致力于拓展全球版图，实现医疗大数据与人工智能的国际战略布局。',
    teamScale: '团队规模',
    teamInfo:
      '中国肾脏病大数据协作网管理委员会是本数据平台的管理机构，主任委员为我国著名肾脏病学专家、中国科学院院士侯凡凡教授，大数据专家为我国著名临床流行病学专家、十四五国家重点研发计划首席科学家徐欣教授，其他团队成员包括生物统计师、数据工程师及肾内科医师等20余人。',
    experts: '专家简介',
    hffIntroduce:
      '中国科学院院士、发展中国家科学院院士。国家肾脏病临床医学研究中心主任、国家器官衰竭防治重点实验室主任、南方医院肾科主任。兼任中华肾脏病学会副主任委员、中国医师协会肾脏病分会副会长、Nat Rev Nephrol (中文版)和Chi J M (英文版)等期刊副主编/编委、国际慢性肾脏病防治指南（KDIGO）工作委员会委员。侯教授是多项国际多中心临床研究指导委员会委员和中国地区负责人。是SONAR、ESBARI、ROAD、CSPPT-CKD等随机对照研究的负责人（PI）。研究成果发表在N Engl J Med、JAMA、JAMA Intern Med、J Am Soc Nephrol、Kidney Int等国际权威期刊，被引用超过5,300次。作为第一完成人获国家科技进步二等奖3项，省部级科技进步一等奖6项，获国家授权发明专利和美国授权发明专利5项。曾获何梁何利基金“科学与技术进步奖”等科技奖励。',
    lmIntroduce:
      '现任南方医院肾内科副主任，国家肾脏病临床医学研究中心副主任，博士生导师。从事肾脏病临床、教学和科研工作近三十年。主要研究方向为慢性肾脏病、肾功能衰竭。以第一负责人承担国家科技支撑计划项目1项，国家自然科学基金面上项目2项，省部级科研课题4项。发表SCI收录论文30余篇，获国家科技进步二等奖2项，获中华医学科技进步一等奖和广东省科技进步一等奖各2项。获国家发明专利授权3项。参编出版专著4部。担任广东省医学教育协会肾脏病学专业委员会主任委员，国家自然科学基金评审专家，中华肾脏病杂志、南方医科大学学报、临床肾脏病杂志等学术期刊编委或审稿专家。',
    xxIntroduce:
      '南方医科大学南方医院肾内科教授，国家肾脏病临床医学研究中心专家，广东省肾脏病研究所教授。曾在在美国辛辛那提大学、哈佛大学公共卫生学院担任博士后、研究助理、助教授和讲座助教授。主要从事心脑肾血管并发症的临床流行病学研究，研究领域包括高血压、代谢综合征、心脑血管等复杂疾病的分子医学和遗传流行病学的研究。通过临床试验及流行病学研究揭示我国人群心脑肾血管并发症防治的临床特征及危险因素，寻找风险预测的生物标志物，评价防治疗效。是中国脑卒中一级预防研究和急性肾损伤回顾性多中心临床流行病学研究的主要负责人之一。曾任6个NIH资助的遗传流行病学及遗传分析RO1项目首席科学家、主要负责人或共同研究者。曾任《Biometrics》杂志编委。在《Nature Genetics》等SCI专业期刊发表论文80余篇，论文被引用次数逾4千次。开发的家族性遗传关联方法（FBAT）被广泛用于复杂疾病的遗传关联分析。',
    xhIntroduce:
      '徐虹教授目前担任复旦大学附属儿科医院党委书记、复旦大学附属儿科医院肾脏科和风湿科学科带头人、上海市肾脏发育和儿童肾脏病研究中心主任，国家儿童医学中心互联网+肾脏专科联盟主任，同时担任上海市政协委员、国际儿科肾脏病学会培训中心（中国上海）主任、国际儿科肾脏病学会理事、中国医师协会儿科医师分会肾脏专家委员会主任委员、中华医学会儿科学分会人文建设委员会主任委员、中国医师协会儿科医师分会血液净化专家委员会副主任委员、中华医学会儿科学分会委员、中国儿童遗尿疾病管理协作组组长、中国儿童肾移植管理专家协作组组长、上海市医学会肾脏病分会副主任委员、香港大学深圳医院儿科学荣誉顾问医生等重要职务，在儿童肾脏病领域具有极高声望。',
    xgIntroduce:
      '现任华中科技大学同济医学院附属同济医院肾内科主任，中华医学会肾脏病学分会常委、中国医师协会肾脏病分会常委、中国医院协会血液净化管理分会全国常委、中华预防医学会肾脏病预防与控制专业委员会副主委、中国研究型医院学会肾脏病专业委员会副主委、湖北省医师协会肾脏病学分会主任委员、湖北省肾脏替代治疗临床研究中心主任、《临床肾脏病杂志》主编，五年制本科临床医学专业第九轮规划教材《内科学》编委、国家卫生和计划生育委员会“十三五”住院医师规范化培训“规划教材内科学 肾脏内科分册（第2版）”副主编。多次承担国家自然科学基金重大研究计划重点支持项目，国家自然科学基金重点国际合作项目及国家自然科学基金面上项目等。多篇论文发表于Cell Metab、Science Adv、Kidney Int、J Soc Am Nephrol等杂志上。',
    wlmIntroduce:
      '2009年来，负责全国慢性病及其危险因素监测工作，成功组织实施了2010-2018年六次中国成人居民和流动人口慢性病及其危险因素监测现场调查和技术指导工作，组织实施了全国脑血管、精神障碍、消化系统疾病和糖尿病并发症流行病学现场调查。主持或参与国家科技部重点研发计划、科技支撑、卫生公益行业专项、国际和国内合作课题10余项，在慢性病防控方面、慢病监测和大数据研究方面具有丰富的经验。组织编写并出版了《中国慢性病及其危险因素监测报告》和《全国疾病监测系统死因监测数据集》等报告13部，参与多部国家慢性病相关研究报告撰写和政策制定，在JAMA和Lancet等国内外期刊发表论文160余篇有影响的论文，获得中华医学会、中华预防医学会和华夏医学科学及技术一、二、三等奖四项。',
    zmgIntroduce:
      '中国疾病预防控制中心慢病中心副主任，研究员，流行病与卫生统计学博士，博士生导师，中华预防医学会健康测量与评价专业委员会主任委员，中国卫生信息与健康医疗大数据学会慢病防治与管理专业委员会主任委员，国家百千万人才工程专家，享受国务院政府特殊津贴。研究领域包括慢性病防控，慢病大数据，人群健康测量与评价，环境与健康等方面。近年来承担和参与十余项国家科技部、自然科学基金和国际合作课题，发表中英文文章二百余篇。',
    zyIntroduce:
      '北京大学医学免疫硕士、清华大学生物信息学博士；中科院计算所研究员、博导。入选2020、2021年度爱思唯尔(Elsevier) “中国高被引学者”，入选全球生物信息学前2%顶尖科学家榜单(美国斯坦福大学2020年发布)；入选2021年科睿唯安Web of ScienceTM全球高被引科学家。研究方向：从事多组学数据、临床数据挖掘及人工智能算法研究。近十年在国际著名期刊以第一作者及通讯作者发表论文60余篇，其中影响因子15以上的论文23篇，11篇论文为ESI高被引用论文。',
    cooperation: '合作中',
    dataInputting: '数据导入中',
    datareviewing: '流程审批中',
    databaseProgress: '数据库建设进度',
    openProjectProgress: '开放课题进度',
    bjttHospital: '首都医科大学附属北京天坛医院',
    sdslHospital: '山东第一医科大学附属省立医院',
    zjdaxfsdeHospital: '浙江大学医学院附属第二医院',
    syfHospital: '浙江大学医学院附属邵逸夫医院',
    syxHospital: '中山大学附属孙逸仙纪念医院',
    szdermHospital: '深圳市第二人民医院',
    zdHospital: '东南大学附属中大医院',
    fdfusekHospital: '国家儿童医学中心复旦大学附属儿科医院',
    scsrmHospital: '四川省医学科学院·四川省人民医院',
    hzzxrmHospital: '惠州市中心人民医院',
    wuhanHospital: '武汉中心医院',
    zjdaxfsdyHospital: '浙江大学医学院附属第一医院',
    haikouHospital: '海口市人民医院',
    shjtfsdjHospital:'上海交通大学医学院附属第九人民医院',
    klHospital:"开滦总医院",
    yuebeiHospital: '粤北人民医院',
    zsdxdsfs: '中山大学附属第三医院',
    youanminzu: '右江民族医学院附属医院',
    hbykdxdyyy: '河北医科大学第一医院',
    nhykdxfsdyyy: '南华大学附属第一医院',
    zndxxyyy: '中南大学湘雅医院',
    zsfsdbHospital:'中山大学附属第八医院',
    whdsHospital:"武汉市第四医院",
    xmfsHospital:'厦门大学附属第一医院',
    heinanHospital: '海南医学院第一附属医院',
    mmrmHospital: '茂名市人民医院，南方医科大学附属茂名医院',
    zgkjdaxfsdyHospital: '中国科学技术大学附属第一医院，安徽省立医院',
    gdszyHospital: '广东省中医院',
    gzsrmHospital: '贵州省人民医院',
    fssdyrmHospital: '佛山市第一人民医院',
    gdykdxfsHospital: '广东医科大学附属医院',
    nfykdaxfsdsHospital: '南方医科大学第三附属医院',
    nfykdxfssdHospital: '南方医科大学顺德医院',
    glyxyfsHospital: '桂林医学院附属医院',
    zjdxfsetHospital: '浙江大学附属儿童医院',
    szrmHospital:'深圳市人民医院',
    sgrmHospital: '韶关市第一人民医院',
    shdyrmHospital: '上海市第一人民医院',
    cqxnHospital: '陆军军医大学西南医院',
    hzHospital: '南方医院赣州医院',
    kmdxfsdeHospital: '昆明医科大学第二附属医院',
    hzsfnetylzxHospital: '广州市妇女儿童医疗中心',
    zsdxfsdwHospital: '中山大学附属第五医院',
    xjhrmHospital: '喀什地区第一人民医院',
    rkHospital: '广西中医药大学附属瑞康医院',
    qhdxfscqxnHospital: '青海大学附属医院',
    xjykdyfsHospital: '新疆医科大学第一附属医院',
    dlykdxfsdeHospital: '大连医科大学附属第二医院',
    projectOngoing: '进行中',
    underReview: '未驻场',
    applying: '申请中',
    openProjectandReviewSystem: '开放课题申请及评审制度',
    download: '下载',
    application: '申请及评审流程',
    pleaseEmail: '沟通项目合作意向发送邮件至',
    notes:
      '注意事项：加入协作网须获得医院相关部门及伦理委员会的批准，导出数据均为脱敏后的临床数据，不涉及患者隐私',
    openProjectApplication: '开放课题申请表',
    openResearchPro: '开放课题研究方案',
    openSubjectMeth: '开放课题管理办法',
    hff: '侯凡凡',
    lm: '梁敏',
    xx: '徐欣',
    xh: '徐虹',
    xg: '徐钢',
    wlm: '王丽敏',
    zmg: '周脉耕',
    zy: '赵屹',
    News1Title: '南方医院肾内科获”十四五“国家重点研发计划项目资助',
    News1TitleC:
      '新年伊始，南方医院肾内科传来喜讯。由（南方医院）国家肾脏病临床医学研究中心牵头，联合复旦大学国家儿童医学中心和国家老年疾病临床医学研究中心、中国疾病与预防控制中心慢病中心、中科院计算技术研究所、华中科技大学、神州医疗科技股份...',
    News1Part1:
      '新年伊始，南方医院肾内科传来喜讯。由（南方医院）国家肾脏病临床医学研究中心牵头，联合复旦大学国家儿童医学中心和国家老年疾病临床医学研究中心、中国疾病与预防控制中心慢病中心、中科院计算技术研究所、华中科技大学、神州医疗科技股份有限公司等单位申报的十四五国家重点研发项目“慢性肾脏病发生、发展及预后的风险预测体系（2021YFC2500200）”获得国家科技部正式批准，项目预算4000万元，其中中央财政拨款3000万元。本项目是肾内科继国家“973”、“863”计划项目以来承担的又一国家重大科研项目，标志着南方医院肾内科在国内肾脏病学界的科研领军地位。',
    News1Part2:
      '该项目由肾内科徐欣教授担任总负责人。徐欣教授2014年作为高层次人才引进我院，目前是国家肾脏病临床医学研究中心大数据中心负责人，拥有丰富的临床科研经验。近年来在侯院士指导下，以第一或通讯作者在JAMA Intern Med、Nature Medicine、Nature Review Nephrology、JASN等知名杂志上发表一系列高水平论著。',
    News1Part3:
      '此次申报的“慢性肾脏病发生、发展及预后的风险预测体系”项目，围绕肾脏病“发生-发展-预后”全疾病流程,针对“早期诊断-进展风险评估-疗效和预后预测”全诊疗链条,融合“临床-病理-组学”多模态数据、创建覆盖“儿童-成人-老年人”全生命周期的肾脏病风险预测体系。项目整合了我国肾脏病临床研究、流行病学研究、多组学生物标志物研究及人工智能算法等多学科顶尖团队，发挥了疾控、医疗、科研院校及信息技术等多机构协作的优势，利用了肾脏病医疗健康大数据、国家疾控中心的慢病及危险因素监测和死亡监测系统、以及多中心的成人、儿童和老年队列数据及生物样本，最终将构建并验证适用于不同患者人群和不同医疗场景的多模态慢性肾脏病风险预测体系，开发适合临床应用的风险预测模型和辅助决策工具，在全国医院推广应用，为我国慢性肾脏病的精准防治提供循证依据。',
    News2Title:
      '2021年度肾脏病研究进展南方论坛暨广东省医学教育协会 肾脏病学专业委员会学术年会顺利召开',
    News2TitleC:
      '2021年10月15日-16日由器官衰竭国家重点实验室、国家慢性肾脏病临床医学研究中心、广东省医学教育协会共同举办的“2021年度肾脏病研究进展南方论坛暨广东省医学教育协会肾脏病专业委员会学术年会”在广州顺利召开。会议邀请了中国科学院葛...',
    News2P1: '开幕式致辞',
    News2P2: '院士讲座',
    News2P3: '大会合影',
    News2Part1:
      '2021年10月15日-16日由器官衰竭国家重点实验室、国家慢性肾脏病临床医学研究中心、广东省医学教育协会共同举办的“2021年度肾脏病研究进展南方论坛暨广东省医学教育协会肾脏病专业委员会学术年会”在广州顺利召开。会议邀请了中国科学院葛均波院士、赵国屏院士、杨焕明院士、顾东风院士、侯凡凡院士以及中华医学会肾脏病学专业委员会陈江华主任委员、法国国家科学院外籍院士陈楠教授等来自全国各地100名专家代表，600余名医护人员以及硕博士学生参与了本次会议。',
    News2Part2:
      '广东省医学教育协会肾脏病学专委会主任委员、国家肾脏病临床医学研究中心副主任、南方医科大学南方医院肾内科副主任梁敏教授担任大会执行主席，主持了本次会议。侯凡凡院士、广东省医学教育协会周增桓副会长、南方医科大学黎孟枫校长、中华医学会肾脏病学专业委员会陈江华主任委员在开幕式上先后致辞。南方医科大学科研院张虹副院长、南方医院孙剑副院长等依托单位领导出席会议。',
    News2Part3:
      '大会共设置57个学术讲座，其中包括5个院士专题讲座，采用了线下授课和线上直播相结合的方式，超五千余人线上注册学习。由葛均波院士、顾东风院士、赵国屏院士、杨焕明院士和侯凡凡院士就心血管疾病、人类基因组、大数据与肾脏病研究等专题进行了讲座。其他学术报告涵盖了慢性肾脏病的最新研究进展、肾脏病精准治疗策略等相关专题，学术研究成果丰硕，覆盖全面。',
    News2Part4:
      '本次大会是广东省医学教育协会肾脏病专委会（以下简称专委会）成立以来举办的第五次学术年会。专委会主任委员梁敏教授表示，专委会自2017年成立至今，积极组织广东省周边城市进行医学教育交流、帮扶等活动，积极邀请全国专家进行学术汇报，增强学术影响，为广大肾脏病领域基层医护人员提供良好的学习机会和交流平台，也为华南地区的肾脏病研究进展与诊疗服务贡献力量！',
    News3Title: '国家慢性肾病临床医学研究中心召开学术委员会',
    News3TitleC:
      '2021年10月15日，国家慢性肾病临床医学研究中心学术委员会在广州召开。会上，通过了以中科院葛均波院士为主任委员的学术委员会，委员包括中科院赵国屏院士、杨焕明院士、顾东风院士、侯凡凡院士以及上海交通大学医学院附属瑞金医院陈...',
    News3Part1:
      '2021年10月15日，国家慢性肾病临床医学研究中心学术委员会在广州召开。会上，通过了以中科院葛均波院士为主任委员的学术委员会，委员包括中科院赵国屏院士、杨焕明院士、顾东风院士、侯凡凡院士以及上海交通大学医学院附属瑞金医院陈楠教授、南方医科大学高天明教授、南方医科大学南方医院刘友华教授和山东大学易凡教授。南方医科大学黎孟枫校长对新一届学术委员会委员表示热烈祝贺和衷心感谢，希望国家慢性肾病临床医学研究中心在各位专家的指导下，响应国家医疗卫生重大需求，汇集精英，刻苦钻研，不辱使命，多结硕果。',
    News3Part2:
      '葛均波院士为现场的委员们颁发聘书后，国家慢性肾病临床医学研究中心主任侯凡凡院士就平台的建设发展情况进行了汇报。现场的学术委员会委员们对平台的未来发展方向、人才培养及平台管理等方面进行了讨论。',
    News3Part3:
      '本次会议由南方医科大学刘叔文副校长主持，南方医科大学科研院张虹副院长、南方医科大学南方医院孙剑副院长，平台副主任侯金林教授、宾建平教授、梁敏教授等相关人员出席会议。会后，学术委员会委员们参加了“2021年度肾脏病研究进展南方论坛”并分别做了精彩的专题学术讲座。',
    News4Title: '广东省肾脏病研究所中国肾脏病大数据协作网通过数据保藏申请',
    News4TitleC:
      '2021年6月29日，在南方医科大学、省科技厅的大力支持下，中国肾脏病大数据协作网大数据中心完成科技部国家人类遗传资源管理办公室数据保藏申请远程答辩，侯凡凡院士，徐欣教授，聂晟博士等参与本次答辩。',
    News4Part1:
      '2021年6月29日，在南方医科大学、省科技厅的大力支持下，中国肾脏病大数据协作网大数据中心完成科技部国家人类遗传资源管理办公室数据保藏申请远程答辩，侯凡凡院士，徐欣教授，聂晟博士等参与本次答辩。',
    News4Part2:
      '“中国肾脏病大数据协作网”是由国家肾脏病临床医学研究中心（南方医院）、国家疾病预防控制中心慢病中心联合发起，于2019初正式启动，该项目在侯凡凡院士的带领下，基于真实世界医疗健康大数据探索肾脏疾病的防治，通过“协作网”项目的实施，将整合政府相关管理部门、各级医疗机构及患者个人的肾脏病相关数据，构建集肾脏病临床诊疗、防治策略、医疗控费、社区筛查、慢病管理、远程服务和决策支持等多种功能为一体的大数据平台，实现肾脏疾病相关数据的互联融合，开放共享，推动我国肾脏病防治事业的进步，向世界发出肾脏病防治的中国声音。',
    News4Part3:
      '评审专家一致高度认同该项目具有重大的临床意义，一致同意通过国家人遗办审批（审批号：国科遗办审字〔2021〕BC0037号），共建国家医疗与健康大数据平台，以数据为驱动，为人民健康谋福利。',
    News5Title: '侯凡凡院士领衔 广州肾脏病医学中心今日全面开工',
    News5TitleC:
      '6月28日上午，省、市重点建设项目--广州肾脏病中心建设项目正式启动。省卫生健康委、省财政厅、省科技厅、省代建项目管理局、南方医科大学等单位领导出席启动仪式。据南方医院党委书记朱宏介绍，医院在毫不松懈抓好...',
    News5Part1:
      '6月28日上午，省、市重点建设项目--广州肾脏病中心建设项目正式启动。省卫生健康委、省财政厅、省科技厅、省代建项目管理局、南方医科大学等单位领导出席启动仪式。',
    News5Part2:
      '据南方医院党委书记朱宏介绍，医院在毫不松懈抓好疫情防控的同时，统筹做好省、市重点建设项目的推进工作，广州肾脏病医学中心已经按计划完成各项准备工作，今天将全面开工。',
    News5T1: '建设国际一流的肾脏病临床诊疗中心',
    News5Part3:
      '据介绍，广州肾脏病医学中心的建设目标是通过医、学、研、产、政集群发展，打造国际一流的肾脏病临床诊疗中心和国家肾脏病诊治示范中心。',
    News5Part4:
      '中心大楼有6万平方米，拥有包括住院病房、血液净化中心和腹膜透析中心等临床诊疗单元；有国家重点实验室、国家临床医学研究中心，以及生物样本库、大数据中心和实验动物中心等研究机构；还有国际学术交流中心、培训中心和远程会诊中心等等。',
    News5Part5:
      '先进的建设理念、现代的设计与制造，将使未来的广州肾脏病医学中心不仅成为我国肾脏病诊疗和研究的高地，也为广大患者提供舒适、方便和充满人文关怀的就医场所。',
    News5T2: '我国有超1.2亿肾脏病患者',
    News5Part6:
      '中国科学院院士、广州肾脏病医学中心主任侯凡凡介绍，该中心是目前全国唯一一家集国家肾脏病临床医学研究中心、国家重点实验室、国家临床重点专科等多个国家级平台于一体的综合性肾脏病防治与研究中心。肾脏病是一类严重危害人类健康的常见疾病。',
    News5Part7:
      '侯凡凡院士说，我国有超过1.2亿肾脏病患者，更加值得关注的是，当前因慢性肾脏病导致死亡的增长速度在所有非传染性慢性疾病中位居第一。因此，肾脏病已成为影响国民健康和社会经济发展的“公共健康问题”。广东省和广州市决定打造一个具有国际先进水平的肾脏病医学中心，通过汇聚资源、集中攻关、协同创新，攻克肾脏疾病的难关，造福万千病患。',
    News5T3: '“大南医”系统全力支持',
    News5Part8:
      '启动仪式上，南方医院李文源院长向参会领导介绍了医院全面建设情况，并表示一定要高质量按期完成广州肾脏病学中心的建设任务，为广东打造卫生强省和南方医科大学“双一流”建设贡献新的更大力量。',
    News5Part9:
      '南方医科大学校长黎孟枫表示，广州肾脏病医学中心旨在打造服务区域经济社会发展和人民重大健康需求的肾脏疾病临床诊治中心、肾脏疾病防治临床与基础研究中心、肾脏病防治教学与人才培养中心、肾脏疾病防治研究成果产学研转化中心，开工建设的意义重大、影响深远，学校必将举全校之力，在整个“大南医”系统内统筹资源、协调力量、给予支持。',
    News5T4: '助力构建广东卫健“顶天立地”格局',
    News5Part10:
      '广东省卫生健康委员会主任段宇飞代表省卫生健康委对广州肾脏病医学中心建设启动表示热烈的祝贺，他表示，广州肾脏病医学中心是省、市重点工程，是构建广东卫生健康“顶天立地”格局的关键一步。侯凡凡院士是广东省卫生健康界的一面旗帜，他衷心期望侯凡凡院士团队全面对标国际肾脏病前沿，将广州肾脏病医学中心建设成为国际顶尖的肾脏病医学高峰，希望南方医科大学南方医院以广州肾脏病医学中心建设为契机，全面推进公立医院高质量发展，为健康广东建设作出新的最大贡献。',
    News5P1: '6月28日上午，广州肾脏病中心建设项目正式启动',
    News5P2: '中国科学院院士、广州肾脏病医学中心主任侯凡凡在启动仪式上发言',
    News5P3: '广东省卫生健康委员会主任段宇飞在启动仪式上发言',
    News6Title:
      '中国卫生信息与健康医疗大数据学会肾脏病专业委员会关于召开2021年度学术年会暨中国肾脏病大数据协作网高峰论坛会议通知',
    News6TitleC:
      '中国卫生信息与健康医疗大数据学会肾脏病专委员会2021 年度学术年会暨中国肾脏病大数据协作网高峰论坛',
    News6T1: '各有关单位:',
    News6Part1:
      '中国卫生信息与健康医疗大数据学会肾脏病专业委员会(以下简称“专委会”)拟于2021年5月29日在广州市召开2021年度学术年会暨“中国肾脏病大数据协作网”高峰论坛。现将会议有关事宜通知如下:',
    News6Part2: '一、会议时间及地点',
    News6Part3: '(一）会议时间:2021年5月29日全天',
    News6Part4:
      '(二）会议地点:广州万富希尔顿酒店(地址:广东省广州市白云区云城东路515-517号)',
    News6Part5: '二、会议内容',
    News6Part6:
      '大会将以“互联共通、合作共享”为主题，聚焦肾脏病大数据研究领域，力求促进中国肾脏病大数据研究领域的资源融合和创新发展。会议将邀请大数据人工智能、流行病学及肾脏病领域的知名专家作学术报告，同时也将汇报由国家慢性肾病临床医学研究中心（南方医院）发起建设的“中国肾脏病大数据协作网”的Ⅰ期建设情况。',
    News6Part7: '三、主办方及承办方',
    News6Part8: '主办方:中国卫生信息与健康医疗大数据学会',
    News6Part9: '承办方:中国卫生信息与健康医疗大数据学会肾脏病专委会',
    News6Part10: '国家慢性肾病临床医学研究中心（南方医院)南方医科大学南方医院',
    News6Part11: '广东省肾脏病研究所',
    News6Part12: '四、参会人员',
    News6Part13: '(一）中国卫生信息与健康大数据学会相关领导',
    News6Part14: '(二）中国卫生信息与健康大数据学会肾脏病专委会委员',
    News6Part15: '(三)“中国肾脏病大数据协作网”各合作单位专家',
    News6Part16: '(四）大数据研究相关领域专家',
    News6Part17: '五、会议费用',
    News6Part18: '本次大会不收取注册费用，参会人员食宿交通自理。',
    News6Part19: '六、联系方式',
    News6Part20: '会务组联系人:聂晟 联系电话:18825050621',
    News6Part21: '会务组秘书:余梦娟 联系电话:13006300639',
    News6Part22: '会务邮箱:szbdsjxh@163.com',
    News6Part23: '附件:',
    News6Part24: '1.会议日程',
    News6Part25: '2.参会回执',
    News7Title: '中国肾脏病大数据协作网通过大数据伦理批件',
    News7TitleC:
      '2019年10月19日，南方医科大学南方医院主办的中国肾脏病大数据协作网项目通过大数据伦理批件。在南方医科大学南方医院伦理委员会会议室以会议审查方式进行评审，张训，郭志刚,冯茹，刘世霆，沈少林，汤明芳，严金海，薛莲，鲁鸿，吴炳义作为审查委员出席会议...',
    News7Part1:
      '2019年10月19日，南方医科大学南方医院主办的中国肾脏病大数据协作网项目通过大数据伦理批件。在南方医科大学南方医院伦理委员会会议室以会议审查方式进行评审，张训，郭志刚,冯茹，刘世霆，沈少林，汤明芳，严金海，薛莲，鲁鸿，吴炳义作为审查委员出席会议，审查委员一致同意通过，跟踪审查频率为12个月。批件有效期为三年：2019-11-05至2022-11-05。',
    News8Title:
      '中国卫生信息与健康医疗大数据学会肾脏病专业委员会成立大会圆满召开',
    News8TitleC:
      '医疗健康大数据是国家重要的基础性战略资源，其应用落地关乎人民卫生健康事业的发展。为了顺应国家卫健委对健康医疗大数据的基础战略布局，提高中国肾脏疾病的防治水平，4月20日，中国卫生信息与健康医疗大数据学会在广州国际生物岛金域医学...',
    News8Part1:
      '医疗健康大数据是国家重要的基础性战略资源，其应用落地关乎人民卫生健康事业的发展。为了顺应国家卫健委对健康医疗大数据的基础战略布局，提高中国肾脏疾病的防治水平，4月20日，中国卫生信息与健康医疗大数据学会在广州国际生物岛金域医学学术报告厅召开肾脏病专业委员会（以下简称“专委会”）成立大会，并举行第一次全体委员会议。',
    News8Part2:
      '本次大会邀请到了原国家卫生和计划生育委员会副主任、党组成员，现任中国卫生信息与健康医疗大数据学会会长金小桃同志、国家卫生健康委统计信息中心主任兼中国卫生信息与健康医疗大数据学会常务副会长、秘书长张学高主任、广东省卫生健康委员会刘冠贤巡视员以及南方医科大学黎孟枫副校长出席本次大会并致辞。广州市工业信息化局叶小强副巡视员、南方医院鲁鸿副院长、专委会专家教授以及其他参会人员等共计300余人出席了本次会议。',
    News8P1:
      '原国家卫生和计划生育委员会副主任、党组成员，现任中国卫生信息与健康医疗大数据学会金小桃会长致辞',
    News9Title: '“中国肾脏病大数据协作网”启动仪式在京举办',
    News9TitleC:
      '目前，肾脏疾病已成为危害民众健康，耗费巨大卫生资源的全球公共健康问题之一。中国作为世界第一人口大国，有超过1.2亿成人罹患慢性肾脏病，用于终末期肾脏病的肾脏替代治疗每年需耗费数百亿元的卫生资源，疾病的负担日益严重，防治水平...',
    News9Part1:
      '目前，肾脏疾病已成为危害民众健康，耗费巨大卫生资源的全球公共健康问题之一。中国作为世界第一人口大国，有超过1.2亿成人罹患慢性肾脏病，用于终末期肾脏病的肾脏替代治疗每年需耗费数百亿元的卫生资源，疾病的负担日益严重，防治水平亟待提高。',
    News9Part2:
      '为了顺应创新驱动的时代需求，通过整合各级各类医疗卫生机构和管理机构肾脏病患者的相关数据，构建集肾脏病临床诊疗、预防、健康管理、科学研究等多种功能为一体的肾脏病大数据平台，实现肾脏疾病相关数据的互联融合，开放共享，及时掌握肾脏病的发病、患病和死亡状况，为国家制定肾脏病的防控策略和措施、优化临床治疗措施、预测风险等提供科学依据，为肾脏病科学提供大数据支撑。2018年12月9日，由中国疾病预防控制中心慢性非传染性疾病预防控制中心与国家肾脏病临床医学研究中心（南方医院）联合主办的“中国肾脏病大数据协作网”启动仪式在北京人卫酒店隆重举行。中国疾病预防控制中心李新华书记和南方医院侯凡凡院士共同担任大会主席，并揭牌。中国疾控中心慢病中心吴静副主任主持启动仪式，中国疾控中心慢病中心李志新书记和周脉耕副主任，复旦大学附属华山医院等31多家医院院长、科研处、肾脏病科室的负责人等50余人参加了启动仪式。',
    News9Part3:
      '在启动会上，首先，中国疾控中心李新华书记致辞，李书记指出：中国肾脏病大数据协作网的成立突出体现了肾脏病防治与研究模式的创新与探索，主要表现在：大数据技术与健康医疗服务深度融合的创新与探索，大数据支撑下的肾脏病诊疗研究模式的创新与探索和肾脏病医防结合模式的创新与探索。通过建立肾脏病大数据平台，融合各级各类机构肾脏病相关数据，制定肾脏疾病相关数据共建共享机制，开展肾脏病临床诊疗研究、预后评估、远程医疗服务、社区筛查与患者健康管理、经济学评价等，为肾脏病的治疗、预防、科研服务。然后，侯凡凡院士介绍了肾脏病的流行状况、危害和防治措施及面临的挑战；中国疾控中心慢病中心周脉耕副主任报告了“中国肾脏病疾病负担及影响因素”，美国德克萨斯州立大学生物医学信息学院徐华教授做了“医疗大数据分析方法及应用”的报告。最后，中国肾脏病大数据协作网合作模式经过讨论已达成了初步共识，各大医院纷纷表示肾病大数据协作网的启动将对肾脏病的防治与管理起到极大的促进作用，愿意加入该协作网。同时，也提出中国疾控中心慢病中心要积极推动该项工作，争取国家卫健委支持，进一步落实大数据协作网络平台建设，建立数据交换共享机制，为进一步推动我国肾脏病临床防治事业的进步，保障国民健康，为实现“健康中国”战略做出贡献。',
    guangzhou: '广州',
    huizhou: '惠州',
    zhanjiang: '湛江',
    foshan: '佛山',
    guiyang: '贵阳',
    shaoguan: '韶关',
    nanning: '南宁',
    guizhou: '贵州',
    kunming: '昆明',
    chengdu: '成都',
    chongqing: '重庆',
    kasshi: '喀什',
    ganzhou: '赣州',
    hefei: '合肥',
    jinan: '济南',
    nanjing: '南京',
    hangzhou: '杭州',
    dalian: '大连',
    beijing: '北京',
    xining: '西宁',
    wuhan: '武汉',
    shanghai: '上海',
    shenzhen: '深圳',
    zhuhai: '珠海',
    maoming: '茂名',
    tanshan:'唐山',
    xiamen:'厦门',
    mulumuqi:"乌鲁木齐",
    baise:"百色",
    hengyang:"衡阳",
    shijiazhuang:"石家庄",
    guilin:"桂林",
    new1Date: '2022年1月7日',
    new2Date: '2021年10月16日',
    new3Date: '2021年10月15日',
    new4Date: '2021年6月29日',
    new5Date: '2021年6月28日',
    new6Date: '2021年5月14日',
    new7Date: '2019年10月19日',
    new8Date: '2019年4月21日',
    new9Date: '2018年12月14日',
  },
};
