<template>
  <div class="home">
    <div class="disflex news">
      <div class="vertical"></div>
      <div class="f30">{{$t('licence.news')}}</div>
    </div>
    <div class="p-0-250-150">
      <div @click="goLink('News1')" class="cp hover-bc pt-30 pb-30">
        <div class="f20 fb mb-10">
          <!-- 南方医院肾内科获”十四五“国家重点研发计划项目资助 -->
          {{$t('licence.News1Title')}}
        </div>
        <div class="f16 color70">
          <!-- <div>新年伊始，南方医院肾内科传来喜讯。由（南方医院）国家肾脏病临床医学研究中心牵头，联合复旦大学国家儿童医学中心和国家老年疾病临床医学研究中心、中国疾病与预防控制中心慢病中心、中科院计算技术研究所、华中科技大学、神州医疗科技股份...</div> -->
          <div>{{$t('licence.News1TitleC')}}</div>
          <div class="mt-10">{{$t('licence.new1Date')}}</div>
        </div>
      </div>
      <div class="line"></div>
      <div @click="goLink('News2')" class="disflex cp hover-bc pb-30 pt-30">
        <img
          height="140"
          class="mr-20"
          src="@/assets/images/banner-1.png"
        >
        <div>
          <div class="f20 fb mb-10">
            <!-- 2021年度肾脏病研究进展南方论坛暨广东省医学教育协会 肾脏病学专业委员会学术年会顺利召开 -->
            {{$t('licence.News2Title')}}
          </div>
          <div class="f16 color70">
            <!-- <div>2021年10月15日-16日由器官衰竭国家重点实验室、国家慢性肾脏病临床医学研究中心、广东省医学教育协会共同举办的“2021年度肾脏病研究进展南方论坛暨广东省医学教育协会肾脏病专业委员会学术年会”在广州顺利召开。会议邀请了中国科学院葛...</div> -->
            <div>{{$t('licence.News2TitleC')}}</div>
            <div class="mt-10">{{$t('licence.new2Date')}}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div @click="goLink('News3')" class="disflex cp hover-bc pb-30 pt-30">
        <img
          height="140"
          class="mr-20"
          src="@/assets/images/banner-2.png"
        >
        <div>
          <div class="f20 fb mb-10">
            {{$t('licence.News3Title')}}
          </div>
          <div class="f16 color70">
            <div>{{$t('licence.News3TitleC')}}</div>
            <div class="mt-10">{{$t('licence.new3Date')}}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div @click="goLink('News4')" class="cp hover-bc pb-30 pt-30">
        <div class="f20 fb mb-10">
          {{$t('licence.News4Title')}}
        </div>
        <div class="f16 color70">
          <div>{{$t('licence.News4TitleC')}}</div>
          <div class="mt-10">{{$t('licence.new4Date')}}</div>
        </div>
      </div>
      <div class="line"></div>
      <div @click="goLink('News5')" class="disflex cp hover-bc pb-30 pt-30">
        <img
          height="140"
          class="mr-20"
          src="@/assets/images/banner-3.png"
        >
        <div>
          <div class="f20 fb mb-10">
            {{$t('licence.News5Title')}}
          </div>
          <div class="f16 color70">
            <div>{{$t('licence.News5TitleC')}}</div>
            <div class="mt-10">{{$t('licence.new5Date')}}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div @click="goLink('News6')" class="disflex cp hover-bc pb-30 pt-30">
        <img
          height="140"
          class="mr-20"
          src="@/assets/images/banner-4.png"
        >
        <div>
          <div class="f20 fb mb-10">
            {{$t('licence.News6Title')}}
          </div>
          <div class="f16 color70">
            <div>{{$t('licence.News6TitleC')}}</div>
            <div class="mt-10">{{$t('licence.new6Date')}}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div @click="goLink('News7')" class="cp hover-bc pb-30 pt-30">
        <div class="f20 fb mb-10">
          {{$t('licence.News7Title')}}
        </div>
        <div class="f16 color70">
          <div>{{$t('licence.News7TitleC')}}</div>
          <div class="mt-10">{{$t('licence.new7Date')}}</div>
        </div>
      </div>
      <div class="line"></div>
      <div @click="goLink('News8')" class="disflex cp hover-bc pb-30 pt-30">
        <img
          height="140"
          class="mr-20"
          src="@/assets/images/banner-5.png"
        >
        <div>
          <div class="f20 fb mb-10">
            {{$t('licence.News8Title')}}
          </div>
          <div class="f16 color70">
            <div>{{$t('licence.News8TitleC')}}</div>
            <div class="mt-10">{{$t('licence.new8Date')}}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div @click="goLink('News9')" class="disflex cp hover-bc pt-30">
        <img
          height="140"
          class="mr-20"
          src="@/assets/images/banner-6.png"
        >
        <div>
          <div class="f20 fb mb-10">
            {{$t('licence.News9Title')}}
          </div>
          <div class="f16 color70">
            <div>{{$t('licence.News9TitleC')}}</div>
            <div class="mt-10">{{$t('licence.new9Date')}}</div>
          </div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import myFooter from "@/components/footer.vue";

export default {
  name: "Home",
  components: {
    myFooter,
  },
  methods: {
    goLink(name) {
      this.$router.push({ name: name });
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  border: 1px solid #eaeaea;
  // margin: 30px 0;
}
.news {
  padding: 50px 250px 20px;
}
.p-0-250-150 {
  padding: 0 250px 150px;
}
.vertical {
  width: 8px;
  height: 32px;
  margin-right: 10px;
  background: #33B5EE;
}
.color70 {
  color: #707070;
}
.hover-bc:hover{
  background-color: #F7FBFE;
}
</style>
