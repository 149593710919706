<template>
  <div class="home">
    <div class="flex-dir-col align-center pt-50 pb-50">
      <div class="f40">{{$t('licence.briefIntroduction')}}</div>
      <div class="line"></div>
    </div>
    <div class="mytext f24 p-0-250-60-250">{{$t('licence.briefIntroductionInfos')}}</div>
    <div class="p-0-250-30-250">
      <a-carousel
        style="width:1000px;margin:0 auto"
        arrows
      >
        <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: -65px;zIndex: 1"
        >
          <!-- <a-icon type="left-circle" /> -->
          <img
            src="@/assets/images/left.png"
            width="55"
          >
        </div>
        <div
          slot="nextArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="right: -10px"
        >
          <!-- <a-icon type="right-circle" /> -->
          <img
            src="@/assets/images/right.png"
            width="55"
          >
        </div>
        <div class="new-wrap">
          <img
            @click="goLink('News2')"
            height="530"
            width="950"
            src="@/assets/images/banner-1.png"
          >
          <div
            style="bottom: 40px;"
            class="title"
          >2021年度肾脏病研究进展南方论坛暨广东省医学教育协会 肾脏病学专业委员会学术年会顺利召开</div>
        </div>
        <div class="new-wrap">
          <img
            @click="goLink('News3')"
            height="530"
            width="950"
            src="@/assets/images/banner-2.png"
          >
          <div class="title">国家慢性肾病临床医学研究中心召开学术委员会</div>
        </div>
        <div class="new-wrap">
          <img
            @click="goLink('News5')"
            height="530"
            width="950"
            src="@/assets/images/banner-3.png"
          >
          <div class="title">侯凡凡院士领衔 广州肾脏病医学中心今日全面开工</div>
        </div>
        <div class="new-wrap">
          <img
            @click="goLink('News6')"
            height="530"
            width="950"
            src="@/assets/images/banner-4.png"
          >
          <div class="title">中国卫生信息与健康医疗大数据学会肾脏病专业委员会关于召开2021年度学术年会暨中国肾脏病大数据协作网高峰论坛会议通知</div>
        </div>
        <div class="new-wrap">
          <img
            @click="goLink('News8')"
            height="530"
            width="950"
            src="@/assets/images/banner-5.png"
          >
          <div class="title">中国卫生信息与健康医疗大数据学会肾脏病专业委员会成立大会圆满召开</div>
        </div>
        <div class="new-wrap">
          <img
            @click="goLink('News9')"
            height="530"
            width="950"
            src="@/assets/images/banner-6.png"
          >
          <div class="title">“中国肾脏病大数据协作网”启动仪式在京举办</div>
        </div>
      </a-carousel>
    </div>
    <div class="flex-dir-col align-center pt-50">
      <div class="f40">{{$t('licence.database')}}</div>
      <div class="line"></div>
    </div>
    <div class="p-0-250-60-250 disflex justify-sb">
      <img
        src="@/assets/images/database.png"
        width="600"
      >
      <div style="width:400px">
        <div class="f24 pb-20">{{$t('licence.databaseInfos')}}</div>
        <a-button
          @click="goLink('Database')"
          type="primary"
        >
          {{$t('licence.more')}} →
        </a-button>
      </div>
    </div>
    <div class="flex-dir-col align-center pt-50 pb-50">
      <div class="f40">{{$t('licence.team')}}</div>
      <div class="line"></div>
    </div>
    <a-carousel
      class="p-0-250-30-250 team-carousel"
      arrows
    >
      <div
        slot="prevArrow"
        class="custom-slick-arrow"
        style="left: -20px;zIndex: 1"
      >
        <!-- <a-icon type="left-circle" /> -->
        <img
          src="@/assets/images/left.png"
          width="40"
        >
      </div>
      <div
        slot="nextArrow"
        class="custom-slick-arrow"
        style="right: 20px"
      >
        <!-- <a-icon type="right-circle" /> -->
        <img
          src="@/assets/images/right.png"
          width="40"
        >
      </div>
      <div class="team-wrap">
        <div class="per">
          <img
            src="@/assets/images/hff.png"
            width="100%"
          >
          <div class="fb f24">{{$t('licence.hff')}}</div>
          <div class="f18">{{$t('licence.nanFangHospital')}}</div>
        </div>
        <div class="per">
          <img
            src="@/assets/images/lm.png"
            width="100%"
          >
          <div class="fb f24">{{$t('licence.lm')}}</div>
          <div class="f18">{{$t('licence.nanFangHospital')}}</div>
        </div>
        <div class="per">
          <img
            src="@/assets/images/xx.png"
            width="100%"
          >
          <div class="fb f24">{{$t('licence.xx')}}</div>
          <div class="f18">{{$t('licence.nanFangHospital')}}</div>
        </div>
        <div class="per">
          <img
            src="@/assets/images/xh.png"
            width="100%"
          >
          <div class="fb f24">{{$t('licence.xh')}}</div>
          <div class="f18">{{$t('licence.fdekHospital')}}</div>
        </div>
      </div>
      <div
        style="display: flex;"
        class="team-wrap"
      >
        <div class="per">
          <img
            src="@/assets/images/xg.png"
            width="100%"
          >
          <div class="fb f24">{{$t('licence.xg')}}</div>
          <div class="f18">{{$t('licence.hzkjtjHospital')}}</div>
        </div>
        <div class="per">
          <img
            src="@/assets/images/wlm.png"
            width="100%"
          >
          <div class="fb f24">{{$t('licence.wlm')}}</div>
          <div class="f18">{{$t('licence.gjjk')}}</div>
        </div>
        <div class="per">
          <img
            src="@/assets/images/zyg.png"
            width="100%"
          >
          <div class="fb f24">{{$t('licence.zmg')}}</div>
          <div class="f18">{{$t('licence.zgjb')}}</div>
        </div>
        <div class="per">
          <img
            src="@/assets/images/zy.png"
            width="100%"
          >
          <div class="fb f24">{{$t('licence.zy')}}</div>
          <div class="f18">{{$t('licence.zkyjss')}}</div>
        </div>
      </div>
    </a-carousel>
    <div class="tc">
      <a-button
        @click="goLink('Expert')"
        type="primary"
      >
        {{$t('licence.more')}} →
      </a-button>
    </div>
    <div class="flex-dir-col align-center pt-50 pb-50">
      <div class="f40">{{$t('licence.partners')}}</div>
      <div class="line"></div>
    </div>
    <div class="p-0-250-30-250">
      <div class="disflex justify-sa">
        <img
          src="@/assets/images/nf.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/bjtt.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/sdsl.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/zdfsde.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/syf.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/syx.png"
          width="160"
          height="160"
        >
      </div>
      <div class="disflex justify-sa mt-50">
        <img
          src="@/assets/images/sddy.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/dndfszd.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/fdek.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/scrm.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/hzzxrm.png"
          width="160"
          height="160"
        >
        <img
          src="@/assets/images/hzkj.png"
          width="160"
          height="160"
        >
      </div>
    </div>
    <div
      class="tc"
      style="margin-bottom:150px"
    >
      <a-button
        @click="goLink('Project')"
        type="primary"
      >
        {{$t('licence.more')}} →
      </a-button>
    </div>
    <!-- <china-map id="xin1"></china-map> -->
    <my-footer></my-footer>
  </div>
</template>

<script>
import myFooter from "@/components/footer.vue";
import chinaMap from "@/components/chinaMap.vue";

export default {
  name: "Home",
  components: {
    myFooter,
    chinaMap,
  },
  data() {
    return {};
  },
  methods: {
    goLink(name) {
      if (name == "Project") {
        this.$router.push({ name: name, params: { type: "database" } });
      } else if (name == "JoinUs") {
        this.$router.push({ name: key, params: { type: "joinus" } });
      } else {
        this.$router.push({ name: name });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  width: 100px;
  height: 0px;
  border-bottom: 5px solid #1c2f7f;
}
.new-wrap {
  display: flex !important;
  justify-content: center;
  position: relative;
  .title {
    width: 850px;
    position: absolute;
    color: #fff;
    bottom: 20px;
    font-size: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.team-wrap {
  padding-left: 30px;
  padding-right: 30px;
  display: flex !important;
  justify-content: space-around;
  .per {
    width: 190px;
  }
}

.mytext {
  // text-indent: 30px;
}
.p-0-250-60-250 {
  padding: 0 250px 60px 250px;
}
.p-0-250-30-250 {
  padding: 0 250px 30px 250px;
}
</style>
<style  lang="scss" >
.ant-carousel .slick-dots-bottom {
  bottom: 4px;
}
</style>
<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  /* height: 300px;
  line-height: 160px; */
  /* background: #364d79; */
  /* overflow: hidden; */
}
.ant-carousel >>> .custom-slick-arrow {
  text-align: center;
  width: 0px;
  height: 0px;
  font-size: 0px;
  color: #000;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.8;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  color: #000;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}
.ant-carousel >>> .slick-slide h3 {
  /* color: #fff; */
}
</style>
