<template>
  <div class="china-map" :id="mId"></div>
</template>

<script>
import "../../node_modules/echarts/map/js/china.js";

export default {
  props: ["id"],
  data() {
    return {
    };
  },
  computed: {
    mId() {
      if (this.id) {
        return this.id;
      } else {
        return "map";
      }
    },
  },
  methods: {
    setChinaMap() {
      let self = this;

      let option = {
        backgroundColor: "#132766", // 图形容器背景色
        // visualMap的详细配置解析：https://echarts.baidu.com/option.html#visualMap
        visualMap: {
          show: false,
          // 左下角的颜色区域
          type: "continuous", // 定义为分段型 visualMap
          min: 0,
          max: 100,
          itemWidth: 10,
          itemHeight: 100,
          // top: 20,
          bottom: 10,
          left: 20,
          text: ["高", "低"],
          textStyle: {
            // 文字颜色
            color: "#ddd",
          },
          calculable: false, // 是否显示手柄
          inRange: {
            color: ["#0c1c30", "#216bc2", "#7760f6", "#9900FF"],
            // symbolSize: [60, 100] // 大小（这里会影响涟漪散点图的显示，所以去掉）
          },
        },
        // geo配置详解： https://echarts.baidu.com/option.html#geo
        geo: {
          // 地理坐标系组件用于地图的绘制
          map: "china", // 表示中国地图
          // roam: true, // 是否开启鼠标缩放和平移漫游
          zoom: 1, // 当前视角的缩放比例（地图的放大比例）
          // top:20,
          label: {
            show: false,
            color: "#f2f2f2", // 文字颜色
          },
          itemStyle: {
            // 地图区域的多边形 图形样式。
            areaColor: "#4b64a4aa", // 地区默认颜色
            borderColor: '#678dd6', //片区边框颜色
            borderWidth: 0, // 边框描绘
            borderColor: "#90c5ed", //片区边框颜色
            emphasis: {
              // 高亮状态下的多边形和标签样式
              areaColor: "#5DA8EE", // 高亮区域背景颜色
              shadowBlur: 20,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        series: [
          {
            // name: '', // 浮动框的标题（上面的formatter自定义了提示框数据，所以这里可不写）
            type: "map",
            geoIndex: 0,
            label: {
              show: true,
            },
            // 这是需要配置地图上的某个地区的数据，根据后台的返回的数据进行拼接（下面是我定义的假数据）
          },
        ],
      };
      let myChart_map = this.$echarts.init(document.getElementById(this.mId));
      myChart_map.setOption(option);
    },
  },
  mounted() {
    this.setChinaMap();
  },
};
</script>

<style lang='scss' scoped>
.china-map {
  width: 50%;
  max-width: 700px;
  min-width: 500px;
  height: 400px;
}
</style>